<template>
  <div>
    <div class="live-wrapper teams-wrapper mb-2" :class="{'flex-row-reverse': reversedTeams}">
      <div class="half">
        <a @click="selectEntity('team', 'home', model.homeTeam.uuid)"
           v-longpress="(event) => teamEntityClicked(event,'homeTeam', model.homeTeam)"
           class="btn action-button team unselectable"
           style="width: 100%;"
           :style="{ color: '#282828', background: homeTeamColor }"
           :class="{'btn-success': relatedTo === 'team' && teamOn === 'home'}">
          {{ model.homeTeam.name }}
        </a>
      </div>
      <div class="half">
        <a @click="selectEntity('team', 'away', model.awayTeam.uuid)"
           v-longpress="(event) => teamEntityClicked(event, 'awayTeam', model.awayTeam)"
           class="btn action-button team unselectable"
           :style="{ color: '#282828', background: awayTeamColor }"
           :class="{'btn-success': relatedTo === 'team' && teamOn === 'away'}"
           style="width: 100%;">{{ model.awayTeam.name }}
        </a>
      </div>
    </div>
    <div class="live-wrapper" :class="{'flex-row-reverse': reversedTeams}">
      <div class="half">
        <a
          v-for="player in model.homeTeam.players"
          :key="player.uuid"
          class="btn action-button player mb-2 text-left unselectable"
          style="width: 100%;"
          :class="{'bg-success': log.on === player.uuid,
                    'bg-info': log.teammate === player.uuid,
                    'disabled': disableHome,
                    'bg-warning': log.against === player.uuid}"
          v-longpress="(event) => playerEntityClicked(event, 'homeTeam', player)"
          @click="selectEntity('player', 'home', player.uuid)"
          :style="{color: autoActionColor('homeTeam', player)}"
        >
          <span class="number">{{ player.number_of_jersey }}</span>
          <span class="name">{{ player.name }}</span>
        </a>
      </div>
      <div class="half">
        <a
          v-for="player in model.awayTeam.players"
          :key="player.uuid"
          class="btn action-button player mb-2 text-left unselectable"
          :class="{'bg-success': log.on === player.uuid,
                    'bg-info': log.teammate === player.uuid,
                    'disabled': disableAway,
                    'bg-warning': log.against === player.uuid}"
          style="width: 100%;"
          v-longpress="(event) => playerEntityClicked(event,'awayTeam', player)"
          @click="selectEntity('player', 'away', player.uuid)"
          :style="{color: autoActionColor('awayTeam', player)}"
        >
          <span class="number">{{ player.number_of_jersey }}</span>
          <span class="name">{{ player.name }}</span>
        </a>
      </div>
    </div>
    <div
      class="popup-wrapper"
      :class="{'show': showMobileTeamActionsPopup || showMobilePlayerActionsPopup || mobileSettingsValue}"
    >
      <div
        class="popup"
      >
        <div v-if="showMobileTeamActionsPopup" class="popup-inner">
          <h4 v-if="config.live.by_period && relatedTo === 'team' && teamOn === 'home'" class="text-center mb-3">
            {{ model.homeTeam.name }}</h4>
          <h4 v-if="config.live.by_period && relatedTo === 'team' && teamOn === 'away'" class="text-center mb-3">
            {{ model.awayTeam.name }}</h4>
          <div class="popup-body">
            <div
              v-if="config.live.by_period && relatedTo === 'team' && teamOn === 'home'"
              class="team-period-stats-wrapper mb-4"
            >
              <div class="d-flex align-items-center stat-wrapper">
                <span class="w-100 stat-title">{{ $t('period') }}</span>
                <span
                  v-for="tableHeader in config.periods"
                  :key="'homeTeamByPeriod-' + tableHeader"
                  class="stat"
                >
                  {{ tableHeader }}
                </span>
                <span
                  v-if="model.homeTeam.by_period_stats?.length > model.config.periods"
                  class="stat"
                >
                  OT
                </span>
              </div>
              <div
                v-for="tableBody in config.live.by_period.team_table" :key="'homeTeamByPeriod-' + tableBody"
                class="d-flex align-items-center stat-wrapper"
              >
                <span class="w-100 stat-title">{{ $t(`live.${tableBody}`) }}</span>
                <span
                  v-for="(period, p) in model.homeTeam.by_period_stats"
                  :key="'homeTeamByPeriod-' + p"
                  class="stat"
                >
                  {{ period ? period[tableBody] : '-' }}
                </span>
              </div>
            </div>
            <div
              v-if="config.live.by_period && relatedTo === 'team' && teamOn === 'away'"
              class="team-period-stats-wrapper mb-4"
            >
              <div class="d-flex align-items-center stat-wrapper">
                <span class="w-100 stat-title">{{ $t('period') }}</span>
                <span
                  v-for="tableHeader in config.periods"
                  :key="'awayTeamByPeriod-' + tableHeader"
                  class="stat"
                >
                  {{ tableHeader }}
                </span>
                <span
                  v-if="model.awayTeam.by_period_stats?.length > model.config.periods"
                  class="stat"
                >
                  OT
                </span>
              </div>
              <div
                v-for="tableBody in config.live.by_period.team_table" :key="'awayTeamByPeriod-' + tableBody"
                class="d-flex align-items-center stat-wrapper"
              >
                <span class="w-100 stat-title">{{ $t(`live.${tableBody}`) }}</span>
                <span
                  v-for="(period, p) in model.awayTeam.by_period_stats"
                  :key="'awayTeamByPeriod-' + p"
                  class="stat"
                >
                  {{ period ? period[tableBody] : '-' }}
                </span>
              </div>
            </div>
            <div class="live-wrapper">
              <div v-for="(button, n) in buttonTeamGroups" :key="'buttonTeamGroups-' + n" class="half"
                   style="padding: 5px;">
                <a class="btn action-button position-relative w-100 unselectable" :disabled="log.teamOn === '' || relatedTo !== 'team'"
                   :class="{
                    'disabled': log.teamOn === '' || relatedTo !== 'team',
                    'active': clickedButton === n,
                    'has-label': !isMobile && button.hotKey?.label
                    }"
                   @click="selectType(n)"
                   :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
                  <span>{{ $t('live.sports.' + config.sport + '.game_log_buttons.team.' + n) }}</span>
                  <span v-if="!isMobile && button.hotKey?.label" class="hot-key-label">{{ button.hotKey?.label }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="popup-close d-flex justify-content-end mb-2">
            <button @click="selectEntity('team', '', null)" class="btn btn-sm btn-danger w-100">{{
                $t('cancel')
              }}
            </button>
          </div>
        </div>
        <div v-if="showMobilePlayerActionsPopup" class="popup-inner" id="playerPopup">
          <h4 class="text-center m-0 pt-2" id="playerPopupTitle">
            {{ `${selectedPlayer.number_of_jersey}. ${selectedPlayer.name}` }}</h4>
          <div class="popup-body" id="playerPopupBody" :style="{ 'max-height': playerPopupBodyMaxHeight + 'px'}">
            <div class="bg-light p-1" id="playerStats">
              <div class="d-flex align-items-center justify-content-center player-stat-wrapper">
                <div
                  v-for="(stat, key) in config.live.player_table"
                  :key="stat"
                  class="player-stat"
                >
                  <div class="d-flex flex-column align-items-center player-stat-inner">
                    <span>{{ key }}</span>
                    <p>{{ selectedPlayer[stat] }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="player-actions-wrapper"
              id="playerActions"
              :style="{ 'max-height': playerActionsScroll + 'px'}"
            >
              <div v-for="(buttonGroup, n) in buttonPlayerGroups" :key="'buttonPlayerGroup-' + n" class="live-wrapper">
                <div
                  v-for="(button, buttonType) in buttonGroup"
                  :key="'buttonPlayerTypes-' + buttonType"
                  :class="[button.class || 'half']"
                >
                  <a class="btn action-button position-relative w-100 unselectable" :disabled="log.on === '' || relatedTo !== 'player'"
                     :class="{
                      'disabled': log.teamOn === '' || relatedTo !== 'player' || (type !== buttonType && type !== ''),
                      'active': clickedButton === buttonType,
                      'has-label': !isMobile && button.hotKey?.label
                      }"
                     @click="selectType(buttonType)"
                     :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
                    <span>{{
                        $t('live.sports.' + config.sport + '.game_log_buttons.player.' + buttonType)
                      }}</span>
                    <span v-if="!isMobile && button.hotKey?.label"
                          class="hot-key-label">{{ button.hotKey?.label }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-close d-flex justify-content-end mb-2" id="playerPopupClose">
            <button @click="selectEntity('player', '', null)" class="btn btn-sm btn-danger w-100">{{
                $t('cancel')
              }}
            </button>
          </div>
        </div>
        <div v-if="mobileSettingsValue" class="popup-inner">
          <h4 class="text-center mb-3">{{ $t('game.settings') }}</h4>
          <div class="popup-body">
            <div />
            <div>
              <nav aria-label="Page navigation" class="mb-3">
                <div class="py-2 bg-light">
                  <h4 class="text-center mb-2">Period</h4>
                  <ul class="periods pagination justify-content-center mb-3">
                    <li v-for="period in config.periods" :key="'period_' + period" class="page-item"
                        :class="{active: model.current_period === period}">
                      <a class="page-link mr-2" @click="setPeriod(period)">{{ period }}</a>
                    </li>
                    <li class="page-item" :class="{active: model.current_period === 0}">
                      <a class="page-link" @click="setPeriod(0)">OT</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div class="custom-control custom-switch py-1 px-2 rounded d-flex justify-content-center mb-4">
                <input type="checkbox" class="custom-control-input" :checked="reverseTeamsValue" id="customSwitch1"
                      @change="reverseTeams">
                <label class="custom-control-label" for="customSwitch1" role="button">{{
                    $t('live.switchTeams')
                  }}</label>
              </div>
              <button class="btn btn-warning w-100" @click="endGame()">{{ $t('game.endGame') }}</button>
            </div>
            <div />
          </div>
          <div class="popup-close d-flex justify-content-end mb-2">
            <button @click="showMobileSettings()" class="btn btn-sm btn-danger w-100">{{ $t('close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useLive } from '@/views/live/use/useLive'

export default {
  setup () {
    const live = useLive()

    return { ...live }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.showMobilePlayerActionsPopup) {
        const element = document.getElementById('playerActions')
        this.playerActionsHeight = element.clientHeight
      }
    })
  },
  data () {
    return {
      homeTeamColor: localStorage.getItem('homeTeamColor') ?? '#70b9eb',
      awayTeamColor: localStorage.getItem('awayTeamColor') ?? '#70b9eb'
    }
  },
  computed: {
    mobileSettingsValue () {
      return this.$store.state.main.mobileSettings
    },
    reverseTeamsValue () {
      return this.$store.state.main.reversedTeams
    },
    selectedPlayer () {
      const homePlayers = this.model.homeTeam.players
      const awayPlayers = this.model.awayTeam.players
      const allPlayers = homePlayers.concat(awayPlayers)
      return allPlayers.find(player => player.uuid === this.log.on)
    }
  },
  methods: {
    rerender () {
      this.$forceUpdate()
    },
    onPlayerContextMenuClick (value) {
      this.playerContextMenuClicked(value)
      this.$forceUpdate()
    },
    onTeamContextMenuClick (value) {
      this.teamContextMenuClicked(value)
      this.$forceUpdate()
    },
    reverseTeams () {
      this.$store.commit('main/reversedTeams', this.reverseTeamsValue)
    },
    showMobileSettings () {
      this.$store.commit('main/mobileSettings', this.mobileSettingsValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.live-wrapper {
  display: flex;
  align-items: stretch;
  margin: 0 -4px;
  flex-wrap: wrap;
}

.half {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 4px;
}

.full {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 4px;
}

.teams-wrapper {
  position: sticky;
  top: 68px;
  background: #f5f5f5;
}

.btn {
  font-size: 12px;

  &.player {
    background-color: #dfdfdf;
    justify-content: flex-start;
    &.action-button {
      color: #282828;

      &:active {
        background: #46c37b !important
      }
    }
  }

  &.action-button,
  &.action-button:hover {
    color: #fff;
  }
}

.player {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;

  .number {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    background: #fff;
    height: 20px;
    min-width: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 10px;
  }
}

.popup {
  width: calc(100% - 16px);
  height: calc(100% - 48px);
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: stretch;

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100vw;
    background-color: rgba(#000, 0.2);
    z-index: 999998;
    display: none;
    align-items: flex-start;
    justify-content: center;
    padding-top: 8px;

    &.show {
      display: flex;
    }
  }

  &-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    gap: 8px;
  }

  &-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .col-sm-12 {
      padding: 4px;
    }
  }
}

.timer-toggle {
  bottom: 8px;
  right: 8px;
  z-index: 999999;

  &.time-editing {
    bottom: 48px;
  }
}

.page-item {

  .page-link {
    background-color: #f2f2f2;
  }

  &.active {
    .page-link {
      background-color: #e6e6e6;
    }
  }
}

.player-stat {
  padding: 2px;
  flex: 0 0 16.66666%;
  max-width: 16.66666%;

  &-wrapper {
    margin: -2px;
    flex-wrap: wrap;
  }

  &-inner {
    background-color: #f2f2f2;
    padding: 4px 2px;
  }

  span {
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2px;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
  }
}

.team-period-stats-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 1;

  .stat {
    background-color: #e6e6e6;
    height: 32px;
    width: 32px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &-wrapper {
      gap: 4px;
    }

    &-title {
      background-color: #e6e6e6;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      border-radius: 4px;
    }
  }
}

.player-actions-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-switch .custom-control-label {
  border-radius: 4px;
  padding: 0.25rem 0.5rem 0.25rem 3rem;
  background-color: #f2f2f2;

  &::before {
    top: 6px;
    left: 6px;
  }

  &::after {
    top: 9px;
    left: 10px;
  }
}
</style>
