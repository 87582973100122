<template>
  <div id="page-container"
       :class="{'sidebar-o': $store.state.isNavOpened, 'sidebar-o-xs': $store.state.isNavOpened, 'sidebar-mini': $store.state.isMiniNavOpened}"
       v-if="config"
       class="sidebar-o sidebar-dark side-scroll page-header-fixed">
    <navigation></navigation>
    <topbar></topbar>

    <main id="main-container">
      <transition name="fade">
        <loader v-if="this.$store.state.loading"></loader>
      </transition>
      <router-view :key="currentClient.uuid"></router-view>
    </main>

    <!-- END Main Container -->
    <bottombar></bottombar>
  </div>

</template>

<script>
import Topbar from '@/components/layout/Topbar'
import Navigation from '@/components/layout/Navigation'
import Bottombar from '@/components/layout/Bottombar'
import Loader from '@/components/layout/Loader'
import App from '../oneui/app'

export default {
  name: 'Default',
  components: {
    Topbar,
    Navigation,
    Bottombar,
    Loader
  },
  data () {
    return {
      activities: {
        data: [],
        meta: {
          current_page: 0,
          last_page: 1
        }
      }
    }
  },
  created () {
    // eslint-disable-next-line
    jQuery(() => {
      // Create a new instance of App
      window.One = new App()
    })
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    },
    config () {
      return this.$store.state.config
    }
  }
}
</script>

<style lang="scss">
  .fade-leave-active {
    transition-property: opacity;
    transition-duration: .1s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  #main-container {
    position: relative;
  }
</style>
