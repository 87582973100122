import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'flag-icon-css/css/flag-icon.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import RegisterPlugin from './components/register'
import ApiService from './services/api.service'
import { TokenService } from './services/storage.service'
import i18n from './i18n'
import VueFormWizard from 'vue-form-wizard'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'
import vClickOutside from 'v-click-outside'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.compact.css'
import 'vue-swatches/dist/vue-swatches.css'
import * as Sentry from '@sentry/vue'
import { TimerService } from '@/services/stopwatch/timer.service'

// Import FilePond styles
require('./oneui/app.js')
require('./assets/sass/main.scss')

Vue.config.productionTip = false
Vue.use(vClickOutside)
Vue.use(RegisterPlugin)
Vue.use(VueFormWizard)
// Set the base URL of the API
TimerService.init(process.env.VUE_APP_TIMER_URL)
ApiService.init(process.env.VUE_APP_BASE_URL)

Vue.prototype.$store = store

store.dispatch('getAvailableLanguages')
store.dispatch('initLanguage')

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader()
  ApiService.mountInterceptors()
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay({
      maskAllText: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
