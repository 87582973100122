<template>
  <div class="home">
    <page-content>
      <div class="row">
        <div class="offset-md-3 col-md-6">
          <ntm-block>
            <div class="p-sm-3 px-lg-4 py-lg-5">
              <p>{{$t('enter-code.welcome')}}</p>

              <form @submit.prevent="handleSubmit">
                <formly-form :form="form" :model="model" :fields="fields"></formly-form>
                <span v-if="errorCode === 401" class="help-block text-danger d-block mb-2">{{ $t('game-code.notValid') }}</span>
                <button type="submit" class="btn btn-block btn-primary d-flex align-items-center justify-content-center">
                  <i v-if="!loading" class="fa fa-sign-in mr-1"></i> <i v-if="loading" class="fa fa-cog mr-1 fa-spin"></i> Enter game
                </button>
              </form>
            </div>
          </ntm-block>
        </div>
      </div>

    </page-content>
  </div>
</template>
<script>
import GameService from '@/services/game.service'
import ApiService from '@/services/api.service'

export default {
  data () {
    return {
      loading: false,
      errorCode: null,
      model: {
        code: ''
      },
      form: {},
      fields: [
        {
          key: 'code',
          type: 'input',
          required: true
        }
      ]
    }
  },
  methods: {
    handleSubmit () {
      ApiService.setGameCode(this.model.code)
      GameService.status().then(() => {
        this.$router.push({ name: 'preGame', params: { clientId: this.$route.params.clientId, code: this.model.code } })
      }).catch((error) => {
        this.errorCode = error.response.status
      })
    }
  }
}
</script>
