import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import { TokenService } from '@/services/storage.service'
import VueI18n from '../i18n'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const axiosInstance = axios.create({
  headers: {
    common: {
      Accept: 'application/json'
    },
    post: {
      Accept: 'application/json'
    }
  }
  /* other custom settings */
})

const ApiService = {

  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init (baseURL) {
    axiosInstance.defaults.baseURL = baseURL
  },

  setHeader () {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`
  },

  setGameCode (code) {
    axiosInstance.defaults.headers.common.code = code
  },

  setClient (clientId) {
    axiosInstance.defaults.headers.common['X-ORGANISATION'] = clientId
  },

  removeHeader () {
    axiosInstance.defaults.headers.common = {}
  },

  get (resource, options = {}) {
    return axiosInstance.get(resource, options)
  },

  postWithoutLoading (resource, data) {
    return axiosInstance.post(resource, data, { headers: { withoutLoading: true } })
  },
  post (resource, data) {
    return axiosInstance.post(resource, data)
  },
  postBlob (resource, data) {
    return axiosInstance.post(resource, data, {
      responseType: 'blob',
      headers: { withoutLoading: true }
    })
  },
  postMultipart (resource, data) {
    return axiosInstance.post(resource, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  put (resource, data) {
    return axiosInstance.put(resource, data)
  },

  putWithoutLoading (resource, data) {
    return axiosInstance.put(resource, data, { headers: { withoutLoading: true } })
  },

  delete (resource) {
    return axiosInstance.delete(resource)
  },

  deleteWithoutLoading (resource) {
    return axiosInstance.delete(resource, { headers: { withoutLoading: true } })
  },

  deleteWithoutAsking (resource) {
    return axiosInstance.delete(resource, { headers: { withoutLoading: true, withoutAsking: true } })
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest (data) {
    return axiosInstance(data)
  },

  mountInterceptors: function () {
    axiosInstance.interceptors.request.use(async function (config) {
      if ((['put', 'post'].includes(config.method)) && !config.headers.withoutLoading) {
        if (!config.url.includes('/media/') && !config.url.includes('/user/editSeason') && !config.url.includes(
          '/changeGroup')) {
          store.commit('loading')
        }
      }

      if (config.method === 'delete' && !config.headers.withoutAsking) {
        await Vue.swal({
          title: VueI18n.t('swal.delete.title'),
          text: VueI18n.t('swal.delete.text'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: VueI18n.t('swal.delete.yes'),
          cancelButtonText: VueI18n.t('swal.delete.no'),
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (!result.value) {
            throw new axios.Cancel('Request is canceled by the user.')
          } else {
            if (!config.headers.withoutLoading) {
              store.commit('loading')
            }
          }
        })
      }
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })

    this._401interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (!response.request.responseURL.includes('/media/') && !response.request.responseURL.includes('/export/') && typeof response.data === 'string' && response.config.method !== 'delete') {
          // store.commit('loaded')
          if (response.data.length < 25) {
            Toast.fire({
              icon: 'success',
              title: VueI18n.t(response.data)
            })
          }
        }
        if (typeof response.data === 'string' && response.config.method === 'delete') {
          console.log(response.data.length)

          Toast.fire({
            icon: 'success',
            title: VueI18n.t(response.data),
            length: 5000
          })
        }
        // store.commit('loaded')
        return response
      },
      async (error) => {
        if (error.request?.status === 401) {
          if (error.config.url.includes('/oauth/token')) {
            // Refresh token has failed. Logout the user
            store.dispatch('auth/logout')
            throw error
          } else {
            // Refresh the access token
            try {
              await store.dispatch('auth/refreshToken')
              // Retry the original request
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data
              })
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error
            }
          }
        }

        if (error.request?.status === 422) {
          store.commit('loaded')
          Vue.$notify(error.response.data.message.split('|')[0], 'error')
          throw error
        }

        if (error.request?.status === 400) {
          if (error.config.url.includes('/oauth/token')) {
            store.dispatch('auth/logout')
          } else {
            store.commit('loaded')
            Vue.$notify(VueI18n.t(error.response.data), 'error')
            throw error
          }
        }

        if ([0, 500, 403, 400].includes(error.request?.status)) {
          if (error.config.url.includes('/admin/user')) {
            store.dispatch('auth/logout')
          }
        }

        // If error was not 401 just reject as is
        throw error
      }
    )
  },

  unmountInterceptors () {
    // Eject the interceptor
    axiosInstance.interceptors.response.eject(this._401interceptor)
  }
}

export default ApiService
