<template>
  <nav id="sidebar" aria-label="Main Navigation">
    <!-- Side Header -->
    <div class="content-header bg-white-5">
      <!-- Logo -->

        <multiselect
          v-if="clients.length > 1"
          style="max-width: 300px"
          :allow-empty="false"
          track-by="uuid"
          select-label=""
          deselect-label=""
          selected-label=""
          class="organisation-select flex-grow-1"
          :custom-label="(option) => option.name"
          :placeholder="$t('search')"
          v-model="currentClient"
          :options="clients"
        >
        </multiselect>
      <router-link v-if="clients.length === 1" class="font-w600 text-dual" :to="{name:'enterTheGame'}">
         {{ currentClient.name }}
      </router-link>
      <!-- END Logo -->

      <!-- Options -->
      <div>
        <!-- Close Sidebar, Visible only on mobile screens -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <a class="d-lg-none text-dual ml-3" @click="closeNav()" href="javascript:void(0)">
          <i class="fa fa-times"></i>
        </a>
        <!-- END Close Sidebar -->
      </div>
      <!-- END Options -->
    </div>
    <!-- END Side Header -->

    <!-- Side Navigation -->
    <div class="content-side content-side-full">
      <ul class="nav-main">
        <li class="nav-main-item">
          <router-link class="nav-main-link" :to="{name:'enterTheGame'}">
            <i class="nav-main-link-icon fa fa-sign-in"></i>
            <span class="nav-main-link-name">{{ $t('navbar.enterTheGame') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- END Side Navigation -->
  </nav>
</template>

<script>
import router from '@/router'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  computed: {
    mainLinks () {
      return []
    },
    currentSeasonLinks () {
      return [
        {
          title: 'schedule.name',
          icon: 'fa fa-calendar',
          route: { name: 'schedules.index', params: { clientId: this.currentClient.uuid } }
        }
      ]
    },
    settingsLinks () {
      return []
    },
    currentClient: {
      get () {
        return this.$store.state.currentClient
      },
      async set (value) {
        await this.$store.dispatch('setClient', value)
        console.log('currentClient', this.currentClient.uuid)
        this.$router.push('/')
      }
    },
    clients () {
      return this.$store.state.user.clients
    }
  },
  methods: {
    goToRoute (route) {
      router.push(route)
    },
    closeNav () {
      this.$store.dispatch('closeNav')
    }
  }
}
</script>
