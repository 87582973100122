<template>
  <div class="form-group"
       :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
    <multiselect v-if="isString"
                 @search-change="getOptions"
                 :multiple="field.multiple ? field.multiple : false"
                 :custom-label="to.customLabel ? to.customLabel : customLabel"
                 :placeholder="$tc('select.option', field.multiple ? 2 : 1)"
                 :allow-empty="false"
                 v-model="model[field.key]"
                 :options="options"></multiselect>
    <multiselect v-else
                 :track-by="field.trackBy || 'uuid'"
                 @search-change="getOptions"
                 :multiple="field.multiple ? field.multiple : false"
                 :allow-empty="false"
                 :custom-label="to.customLabel ? to.customLabel : customLabel"
                 :placeholder="$tc('select.option', field.multiple ? 2 : 1)"
                 v-model="model[field.key]"
                 :disabled="field.disabled"
                 :options="options"></multiselect>

    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import Multiselect from 'vue-multiselect'
import ErrorDisplay from '../formly-bootstrap/components/errorDisplay'
import VueI18n from '@/i18n'
import { debounce } from '@/lib/debounce'

export default {
  mixins: [baseField],
  data () {
    return {
      options: []
    }
  },
  components: {
    Multiselect,
    ErrorDisplay
  },
  mounted () {
    if (this.field.options) {
      this.options = this.field.options
    } else {
      this.getOptionsInit()
    }
  },
  watch: {
    'field.options': 'updateOptions'
  },
  methods: {
    customLabel (o) {
      const translationObject = this.to.translationObject ?? 'select.options'

      if (this.isString) {
        if (this.to.translated) {
          return VueI18n.t(translationObject + '.' + o)
        }

        return o
      } else {
        if (this.to.translated) {
          return `${o.name ? VueI18n.t(translationObject + '.' + o) : ''}`
        }

        return `${o.name ? o.name : ''}`
      }
    },
    updateOptions () {
      this.options = this.field.options
    },
    getOptionsInit () {
      const params = this.getParams()

      ApiService.get(`api/v1/admin/search/${this.field.optionsApi}`, { params })
        .then((response) => {
          this.options = response.data
        })
    },
    getParams (q = null) {
      const params = {
        search: q || ''
      }

      if (!this.field.additionalQueryParams) {
        return params
      }

      Object.keys(this.field.additionalQueryParams).forEach((key) => {
        params[key] = this.$store.state[this.field.additionalQueryParams[key]]?.uuid
      })

      return params
    },
    getOptions: debounce(function (q) {
      const params = this.getParams(q)

      if (this.field.optionsApi && !this.field.options) {
        ApiService.get(`api/v1/admin/search/${this.field.optionsApi}`, { params })
          .then((response) => {
            this.options = response.data
          })
      }
    }, 200)
  },
  computed: {
    isString () {
      return this.field.isString
    }
  }
}
</script>
