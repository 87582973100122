<template>
  <div id="page-container"
       v-if="config"
       class="sidebar-dark side-scroll page-header-fixed">
    <scorekeeper-topbar></scorekeeper-topbar>

    <main id="main-container">
      <transition name="fade">
        <loader v-if="this.$store.state.loading"></loader>
      </transition>
      <router-view :key="currentClient.uuid"></router-view>
    </main>

    <!-- END Main Container -->
    <bottombar></bottombar>
  </div>

</template>

<script>
import Bottombar from '@/components/layout/Bottombar'
import Loader from '@/components/layout/Loader'
import App from '../oneui/app'
import apiService from '@/services/api.service'
import ScorekeeperTopbar from '@/components/layout/ScorekeeperTopbar.vue'

export default {
  name: 'Default',
  components: {
    ScorekeeperTopbar,
    Bottombar,
    Loader
  },
  async beforeRouteEnter (to, from, next) {
    apiService.setGameCode(to.params.code)
    next()
  },
  created () {
    // eslint-disable-next-line
    jQuery(() => {
      // Create a new instance of App
      window.One = new App()
    })
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    },
    config () {
      return this.$store.state.config
    }
  }
}
</script>

<style lang="scss">
  .fade-leave-active {
    transition-property: opacity;
    transition-duration: .1s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  #main-container {
    position: relative;
  }
</style>
