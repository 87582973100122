<template>
  <router-view :key="currentClient.uuid"></router-view>
</template>

<script>
import { TokenService } from '@/services/storage.service'
import UserService from '@/services/user.service'
import store from '@/store'

export default {
  name: 'MainLayout',
  async beforeRouteEnter (to, from, next) {
    if (TokenService.getToken()) {
      await UserService.getUser().then((response) => {
        return store.commit('currentUser', { ...response.data, currentClientId: to.params?.clientId })
      })
    }

    next()
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    }
  }
}
</script>
